<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'FAQ': 'FAQ',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'FAQ': 'FAQ',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      folders: null
    };
  },
  methods: {
    getList() {
      api
        .get('faq')
        .then(response => {
          this.loading = false
          if (response.data.status == 'success') {
            this.folders = response.data.folders
          } else {
            this.empty = true
          }
        })
        .catch(error => {
          this.errored = error
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Suporte</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>{{ t('FAQ') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!folders" class="text-center">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>

    <div v-else-if="folders && folders.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div v-else>
      <div v-for="(faqs, index) in folders" :key="index">
        <div v-if="faqs.list.length > 0" class="card">
          <div class="card-header rounded bg-default m-1" v-b-toggle="'faq_' + index">
            <h5 class="mb-0 text-white">{{ faqs.name }}</h5>
          </div>
          <b-collapse :id="'faq_' + index" class="card-body">
            <b-card no-body class="mb-1 shadow-none" e v-for="(faq, index) in faqs.list" :key="index">
              <b-card-header header-tag="header" class="rounded" v-b-toggle="'row_' + faq.id">
                <h6 class="m-0">{{ faq.question }}</h6>
              </b-card-header>
              <b-collapse :id="'row_' + faq.id" accordion="my-accordion">
                <b-card-body>
                  <div v-html="faq.answer"></div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </div>
  </Layout>
</template>